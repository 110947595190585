import { cn } from '@/lib/utils'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { ChevronDownThin, ChevronUpThinIcon } from 'components/icons'

import { onEnterKeyPress } from 'utils/keyboard'

interface DropdownOption {
  id: number | string
  label: string
}

interface DropdownProps {
  dataTest?: string
  containerClassName?: string
  buttonContainer?: string
  buttonClassName?: string
  label?: string
  options: DropdownOption[]
  placeholder?: string
  selection?: string | number | null
  setSelection: (id: number | string | null) => void
  onClick?: () => void
}

export default function Dropdown({
  dataTest,
  containerClassName,
  buttonClassName,
  label,
  options,
  placeholder,
  selection,
  setSelection,
  onClick
}: DropdownProps) {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleClick = (id: number | string) => {
    setSelection(id)
    setIsExpanded(false)
    onClick?.()
  }

  const getTextForSelection = (id: number | string) => {
    const option = options.find((o) => o.id === id)
    return option ? option.label : null
  }
  return (
    <div
      data-test={dataTest}
      className={twMerge('relative bg-white', containerClassName)}
    >
      {label && <label className="mb-2 font-medium leading-5">{label}</label>}
      <div
        role="button"
        tabIndex={0}
        className={cn(
          'mt-2 flex h-10 items-center justify-between rounded-sm border border-rb-gray-100 px-2.5 text-sm',
          buttonClassName
        )}
        onClick={() => setIsExpanded(!isExpanded)}
        onKeyUp={onEnterKeyPress(() => setIsExpanded(!isExpanded))}
        data-testid={selection ? `selected-option-${selection}` : 'selected-option-none'}
      >
        <div className={`${!selection ? 'text-rb-gray-300' : ''} truncate`}>
          {selection ? getTextForSelection(selection) : placeholder || 'Select one'}
        </div>
        {isExpanded ? (
          <ChevronUpThinIcon height="7" width="12" fill="#0f0f0f" />
        ) : (
          <ChevronDownThin height="7" width="12" fill="#0f0f0f" />
        )}
      </div>
      {isExpanded && (
        <div data-testid="dropdown-options" className="absolute w-full border bg-white">
          {options.map((option, idx) => {
            return (
              <div key={idx}>
                <div
                  role="button"
                  tabIndex={0}
                  className="m-1.5 cursor-pointer pl-2 text-sm hover:bg-rb-gray-50"
                  onClick={() => handleClick(option.id)}
                  onKeyUp={onEnterKeyPress(() => handleClick(option.id))}
                  data-testid={`option-${option.id}`}
                >
                  {option.label}
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
